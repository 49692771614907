import React, { useState } from 'react';
import { motion } from 'framer-motion';

import Search from '../search/search'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faBars, faCircleNodes, faSignIn, faUser } from '@fortawesome/free-solid-svg-icons'

import styles from './nav.module.css';

function NavigationBar({ onHomeClick, onExperienceClick, onProjectsClick }) {
  const [search, setSearch] = useState(false);
  const [searchExit, setSearchExit] = useState(false);

  function handleSetSearch() {
    setSearchExit(true);

    setTimeout(() => {
      setSearch(false);
      setSearchExit(false);
    }, 150);
  }

  return (
    <div id={styles.nav} className={search ? styles['long-nav'] : ''} bg="light" expand="lg">
      {
        search &&
        <motion.div
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: searchExit ? 0 : 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{ duration: 0.5 }
          } style={{ width: '100%' }}>
          <Search clickLinks={{ "Home": onHomeClick, "Experience": onExperienceClick, "Projects": onProjectsClick }} handleSetSearch={handleSetSearch} />
        </motion.div>
      }
      {
        !search &&
        <span id={styles['nav-manager']}>
          <div id={styles['title']}>
            <FontAwesomeIcon icon={faCircleNodes} id={styles['title-icon']} spin />
            Omar Dajani
          </div>
          <ul id={styles['links-manager']}>
            <span id={styles['links']}>
              <li><a onClick={onHomeClick} className={styles['nav-link']} href="#">Home</a></li>
              <li><a onClick={onExperienceClick} className={styles['nav-link']} href="#">Experience</a></li>
              <li><a onClick={onProjectsClick} className={styles['nav-link']} href="#">Projects</a></li>
              <li><a className={styles['nav-link']} href="#">Blog<sup>SOON</sup></a></li>
              <li><a className={styles['nav-link']} href="https://omardajani.com/files/Omar_Dajani_Resume.pdf" target="_blank">Resumé</a></li>
            </span>
            <div id={styles['icons']}>
              <li id={styles['search-icon']} className={styles['nav-link']}><FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => { setSearch(true) }} /></li>
              <li className={styles['nav-link']}><FontAwesomeIcon icon={faBars} /></li>
            </div>
          </ul>
        </span>
      }
    </div>
  );
}

export default NavigationBar;