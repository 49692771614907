import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import NavigationBar from '../../nav/nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import style from './contact.module.css'

function Contact() {
  const form = useRef();
  const [state, setState] = useState('form');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xxei8ov', 'template_dlvrbl6', form.current, 'gPYvjFiEeo5gWkJpjs')
      .then((result) => {
        setState('success');
      }, (error) => {
        setState('error');
      });
  };

  return (
    <div>
      <NavigationBar />
      {
        state === 'form' ?
        <div id={style['container']}>
          <div id={style['manager']}>
            <div id={style['title']}>
              <p>Would love to hear from you. <br /> Get in touch &#128075;</p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div id={style['row']}>
                <div>
                  <label for="lname">Your name</label><br />
                  <input type="text" id="lname" name="name" />
                </div>
                <div>
                  <label for="lname">Your email</label><br />
                  <input type="email" id="lname" name="email" />
                </div>
              </div>
              <div id={style['message-container']}>
                <label for="lname">Message</label><br />
                <textarea
                  id="w3review"
                  name="message"
                />
              </div>
              <div id={style['button-container']}>
                <input type="submit" value="Submit"></input>
              </div>
            </form>
          </div>
        </div>

        : (state === 'success') ?
        <div id={style['success']}>
          <FontAwesomeIcon id={style['check']} size="4x" icon={faCheckCircle} />
          <p className="large-title">Thanks for getting in touch!</p>
          <p style={{ textAlign: 'center' }} className="body-text">I'll channel my coding magic and get back to you within 24 hours.</p>
        </div>

        : (state === 'error') &&
        <div id={style['error']}>
          <p className="large-title">Yikes! :(</p>
          <p style={{ textAlign: 'center' }} className="body-text">Message delivery failed. It seems our carrier pigeon got distracted by a squirrel.</p>
        </div>
      }
    </div>
  );
}

export default Contact;