import React, { useState } from 'react';
import styles from './search.module.css';
import { motion, AnimatePresence } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faClose, faArrowRight } from '@fortawesome/free-solid-svg-icons'

function SearchResults(input) {
    const lowerInput = input.toLowerCase();
    var searchResults = [];

    const validSearchResults = {
        'Home': 'home',
        'Experience': 'experience',
        'Projects': 'projects',
        'Blog': 'blog',
    };

    for (const key in validSearchResults) {
        var newKey = key.toLowerCase();
        // input may be a substring of a search result
        if (lowerInput.length < newKey.length) {
            if (newKey.startsWith(lowerInput)) {
                searchResults.push(key);
            }
        } else { // check to see if it matches a search result
            if (lowerInput === newKey) {
                searchResults.push(key);
            }
        }
    }

    return searchResults;
}

function Search(props) {
    const [input, setInput] = useState('');
    const results = SearchResults(input);

    const quickLinks = ['Home', 'Experience', 'Projects', 'Blog'];

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    function handleSetSearch() {
        props.handleSetSearch();
    }

    return (
        <div id={styles['search-container-parent']}>
            <div id={styles['search-container']}>
                <div className={styles.left}>
                    <FontAwesomeIcon id={styles['expanded-search-icon']} icon={faMagnifyingGlass} />
                </div>
                <div className={styles.right}>
                    <div id={styles['search-input']}>
                        <form action="">
                            <input type="text" id={styles['search-field']} name="fname" placeholder="Search omardajani.com" onChange={handleInputChange} />
                        </form>
                    </div>
                    <FontAwesomeIcon icon={faClose} onClick={handleSetSearch} />
                </div>

                <div id={[styles['search-suggestions']]}>
                    {
                        input.length === 0 &&
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.6 }}
                            >
                                <p id={styles['title']}>Quick links</p>
                                {
                                    quickLinks.map(link => {
                                        return <div className={[styles['quick-link-container']]}>
                                            <FontAwesomeIcon size='xs' icon={faArrowRight} onClick={handleSetSearch} />
                                            <p onClick={() => {
                                                handleSetSearch();
                                                props.clickLinks[link]();
                                            }} id={styles['quick-link']} className="small-body-text">{link}</p>
                                        </div>
                                    })
                                }
                            </motion.div>
                        </AnimatePresence>
                    }
                    {
                        input.length > 0 &&
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.6 }}
                            >
                                <p id={styles['title']}>Suggested searches</p>
                                {results.length > 0 ?

                                    results.map(link => {
                                        return <div className={[styles['quick-link-container']]}>
                                            <FontAwesomeIcon size='xs' icon={faArrowRight} />
                                            <p onClick={() => {
                                                handleSetSearch();
                                                props.clickLinks[link]();
                                            }} id={styles['search-link']} className="small-body-text">{link}</p>
                                        </div>
                                    }) :
                                    <p className="small-body-text">No luck with your search, huh? That's okay, some things in life are just meant to remain a mystery. Maybe it's time to move on and search for something more attainable, like the perfect cup of coffee.</p>
                                }
                            </motion.div>
                        </AnimatePresence>
                    }
                </div>
            </div>
        </div>
    );
}

export default Search;