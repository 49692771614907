import React, { useState, useEffect } from "react";
import NavigationBar from '../../nav/nav';
import style from './ski.module.css'

function Ski() {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      const response = await fetch("https://omardajani.com/media/ski/");
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const imgs = doc.querySelectorAll("a");
      console.log(imgs)
      const urls = Array.from(imgs)
        .map((img) => img.getAttribute("href"))
        .filter((href) => href.startsWith("ski") || href.startsWith("_ski"));

        const uniqueUrls = [...new Set(urls)];
        console.log(uniqueUrls)
      setImageUrls(uniqueUrls);
    }
    fetchImages();
  }, []);

  return (
    <div>
        <NavigationBar />
        <div className={style.gallery}>
        {
        imageUrls.map((url, index) => (
            url.startsWith("_") ?
            <div key={index} className={style.landscape}>
                <img alt="ski/travel" width="420px" height="300px" src={`https://omardajani.com/media/ski/${url}`} />
            </div>
            :
            <div key={index} className={style.portrait}>
                <img alt="ski/travel" width="217px" height="400px" src={`https://omardajani.com/media/ski/${url}`} />
            </div>
        ))
      }
        </div>
      
    </div>
  );
}

export default Ski;
