import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const FadeIn = ({ props }) => {
    const [hasFadedIn, setHasFadedIn] = useState(false);
    const controls = useAnimation();
    const { ref, inView } = useInView({
        rootMargin: "-50px"
    });

    useEffect(() => {
        if (inView && !hasFadedIn) {
            controls.start("visible");
            setHasFadedIn(true);
        }
    }, [controls, inView, hasFadedIn]);

    return (
        <div ref={ref}>
            <motion.div
                animate={controls}
                initial="hidden"
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 0 }
                }}
                transition={{ duration: 1.5 }}
            >
                <>{props}</>
            </motion.div>
        </div>
    );
};

export default FadeIn;